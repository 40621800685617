<template>
  <WidgetTableColumnFilterWrapper
    type="date"
    :filter-value="appliedResult"
    trigger-filter-by-input-click
    @applyFilter="applyFilter(emit)"
    @resetFilter="resetFilter(emit)">
    <template #input>
      <el-input
        :placeholder="formatFilterRange(appliedResult)"
        type="text"
        disabled
        class="clickable" />
    </template>
    <template #main>
      <el-row
        :gutter="20">
        <el-col :span="5">
          <el-radio
            v-model="radio"
            label="default">
            {{ $t("Дата") }}
          </el-radio>
        </el-col>
        <el-col :span="19">
          <el-date-picker
            v-model="filterData.default"
            v-mask="'##.##.####'"
            type="date"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
            :placeholder="$t('Дата')"
            :teleported="false"
            clearable />
        </el-col>
      </el-row>
      <el-divider />
      <el-row
        :gutter="20">
        <el-col :span="5">
          <el-radio
            v-model="radio"
            label="range">
            {{ $t("Период") }}
          </el-radio>
        </el-col>
        <el-col :span="19">
          <el-date-picker
            v-model="filterData.range"
            v-mask="'##.##.####'"
            :teleported="false"
            type="daterange"
            start-placeholder="Начало периода"
            end-placeholder="Конец периода"
            format="DD.MM.YYYY"
            value-format="DD.MM.YYYY"
            clearable />
        </el-col>
      </el-row>
    </template>
  </WidgetTableColumnFilterWrapper>
</template>

<script setup>
import { formatFilterRange } from '@/utils/formatters'
import useFilter from './composition/useFilter.js'
import WidgetTableColumnFilterWrapper from './WidgetTableColumnFilterWrapper'
import { ref } from 'vue'

const emit = defineEmits(['applyFilter'])
const { filterData,
        appliedResult,
        radio,
        applyFilter,
        setDefaultFilter,
        resetFilter } = useFilter()
setDefaultFilter()
</script>

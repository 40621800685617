<template>
  <div
    v-click-outside="hideFilterDialog"
    class="filter-wrapper">
    <div
      v-if="props.triggerFilterByInputClick"
      data-test-id="column-filter-input"
      @click="toggleFilterDialog">
      <slot name="input" />
    </div>
    <div
      v-else
      @click="hideFilterDialog">
      <slot name="input" />
    </div>
    <el-tooltip
      v-model:visible="isOpen"
      placement="bottom"
      class="filter-wrapper__icon"
      trigger="click"
      effect="light"
      :teleported="teleported">
      <template #content>
        <div
          data-test-id="tooltip"
          class="filter-wrapper__tooltip">
          <slot name="main" />
          <template v-if="!isTextType">
            <el-divider />
            <el-row
              :gutter="20">
              <el-col
                :span="24"
                align="right">
                <el-button
                  data-test-id="tooltip-button-reset"
                  :disabled="!clearable"
                  @click.stop="resetFilter">
                  {{ $t('Очистить') }}
                </el-button>
                <el-button
                  data-test-id="tooltip-button-apply"
                  type="primary"
                  @click.stop="applyFilter">
                  {{ $t('Применить') }}
                </el-button>
              </el-col>
            </el-row>
          </template>
        </div>
      </template>
      <div
        ref="target"
        class="filter-wrapper__buttons"
        @click.stop>
        <el-button
          data-test-id="column-button-toggle"
          text
          class="filter-wrapper__button"
          @click="toggleFilterDialog">
          <el-icon><Filter /></el-icon>
        </el-button>
        <el-button
          v-if="clearable"
          text
          data-test-id="column-button-reset"
          class="filter-wrapper__button filter-wrapper__button--reset"
          @click.stop="$emit('resetFilter')">
          <el-icon><Filter /></el-icon>
        </el-button>
      </div>
    </el-tooltip>
  </div>
</template>

<script setup>
import { computed, ref, watch, inject } from 'vue'
import { Filter } from '@element-plus/icons-vue'

const emit = defineEmits(['applyFilter', 'resetFilter'])
const props = defineProps({
  type: {
    type: String,
    default: () => ''
  },
  filterValue: {
    type: String,
    default: () => ''
  },
  triggerFilterByInputClick: {
    type: Boolean,
    default: false
  },
  teleported: {
    type: Boolean,
    default: true
  }
})
const filterData = ref({
  value: '',
  key: ''
})
const isTextType = computed(() => {
  return props.type === 'text'
})
const clearable = computed(() => {
  return props.filterValue !== ''
})

const isOpen = ref(false)
const applyFilter = () => {
  emit('applyFilter')
  toggleFilterDialog()
}
const resetFilter = () => {
  emit('resetFilter')
  toggleFilterDialog()
}

function toggleFilterDialog () {
  isOpen.value = !isOpen.value
}
function hideFilterDialog () {
  isOpen.value = false
}
watch(isOpen, (value) => {
  const [scrollWrap] =  document.getElementsByClassName('el-scrollbar__wrap el-scrollbar__wrap--hidden-default') || []
  if (scrollWrap) {
    if (value) {
      scrollWrap.addEventListener('scroll', closeDialogIfOutOfView)
    } else {
      scrollWrap.removeEventListener('scroll', closeDialogIfOutOfView, false)
    }
  }
})
function closeDialogIfOutOfView () {
  if (isTooltipParentOutOfView()) toggleFilterDialog()
}

const target = ref(null)
const appContent = inject('appContent')
function isTooltipParentOutOfView () {
  const leftBorderView = appContent.value?.getBoundingClientRect().left
  const rightBorderView = appContent.value?.getBoundingClientRect().right
  return (target.value?.getBoundingClientRect().left - 142 < leftBorderView ||
  target.value?.getBoundingClientRect().right > rightBorderView)
}

</script>
<style lang="scss">
  .filter-wrapper {
     &__tooltip {
      max-width: 380px;
      .el-divider {
        margin: 5px 0;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .filter-wrapper {
    display: flex;
    justify-content: space-between;
    &__buttons {
      display: flex;
    }
    .el-button+.el-button {
      margin-left: 3px;
    }
    &__button {
      position: relative;
      margin-left: 5px;
      font-size: 18px;
      &--reset {
        &::before {
          content: '';
          position: absolute;
          height: 17px;
          width: 3px;
          transform: rotate(-45deg);
          background: gray;
        }
      }
    }
  }
</style>

import { reactive, ref } from 'vue'

export default function () {
  const filterData = reactive({
    default: '',
    range: []
  })
  const radio = ref('')
  const appliedResult = ref('')
  const applyFilter = emit => {
    const filterValue = filterData[radio.value]
    appliedResult.value = filterValue.slice()
    emit('applyFilter', {
      [radio.value]: filterValue
    })
  }
  const setDefaultFilter = () => {
    radio.value = 'default'
  }
  const resetFilter = (emit) => {
    filterData.default = ''
    filterData.range = []
    setDefaultFilter()
    applyFilter(emit)
  }
  return {
    filterData,
    radio,
    appliedResult,
    applyFilter,
    setDefaultFilter,
    resetFilter
  }
}
